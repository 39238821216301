import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from '@shared/shared.module';
import { SocialFabModule } from '@core/social-fab/social-fab.module';

@NgModule({
  imports: [
    SharedModule,
    SocialFabModule
  ],
  exports: [
    HeaderComponent
  ],
  declarations: [
    HeaderComponent
  ]
})
export class HeaderModule { }
