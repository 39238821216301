import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

import { environment } from '@env/environment';
import { AppRoutingModule } from '@routing/app-routing.module';

import { HeaderModule } from '@core/header/header.module';

import { AppStateReducer } from '@store/app-state.reducer';
import { AppStateEffects } from '@store/app-state.effects';
import { PageStoreModule } from '@store/page/page-store.module';
import { ArchiveStoreModule } from '@store/archive/archive-store.module';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,

    IonicStorageModule.forRoot({
      name: '__sebi.dev',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),

    StoreModule.forRoot({ router: routerReducer }, {
      runtimeChecks: {
        strictStateImmutability: !environment.production,
        strictActionImmutability: !environment.production,
        strictStateSerializability: !environment.production,
        strictActionSerializability: !environment.production,
        strictActionWithinNgZone: !environment.production,
        strictActionTypeUniqueness: !environment.production
      }
    }),
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument(
      {
        name: 'Sebi.dev',
        maxAge: 25,
        logOnly: environment.production,
        autoPause: true
        // actionSanitizer: (action: any) => {
        //   return action && action.raw ?
        //     {
        //       ...action,
        //       raw: '<<raw data>>'
        //     } :
        //     action;
        // },
        // stateSanitizer: (state) => state.data ? {
        //   ...state,
        //   data: '<<LONG_BLOB>>'
        // } : state
      }
    ) : [],

    StoreModule.forFeature(AppStateReducer.featureKey, AppStateReducer.reducer),
    EffectsModule.forFeature([AppStateEffects]),
    StoreRouterConnectingModule.forRoot(),
    PageStoreModule,
    ArchiveStoreModule,

    HeaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://statistik.sebi.dev/', siteId: '1' }),
    NgxMatomoRouterModule,
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}