import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ApiDefaultPage } from './api-default-page.model';

export const loadPageByPath = createAction(
  '[Page] Load Page By Path',
  props<{ path: string, forceReload?: boolean }>()
);

export const loadPageSuccess = createAction(
  '[Page] Load Page Success',
  props<{ page: ApiDefaultPage }>()
);

export const loadPageNotModified = createAction(
  '[Page] Load Page Not Modified'
);

export const loadPageFailure = createAction(
  '[Page] Load Page Failure',
  props<{ error: any }>()
);

export const addPage = createAction(
  '[Page/API] Add Page',
  props<{ page: ApiDefaultPage }>()
);

export const upsertPage = createAction(
  '[Page/API] Upsert Page',
  props<{ page: ApiDefaultPage }>()
);

export const addPages = createAction(
  '[Page/API] Add Pages',
  props<{ pages: ApiDefaultPage[] }>()
);

export const upsertPages = createAction(
  '[Page/API] Upsert Pages',
  props<{ pages: ApiDefaultPage[] }>()
);

export const updatePage = createAction(
  '[Page/API] Update Page',
  props<{ page: Update<ApiDefaultPage> }>()
);

export const updatePages = createAction(
  '[Page/API] Update Pages',
  props<{ pages: Update<ApiDefaultPage>[] }>()
);

export const deletePage = createAction(
  '[Page/API] Delete Page',
  props<{ id: string }>()
);

export const deletePages = createAction(
  '[Page/API] Delete Pages',
  props<{ ids: string[] }>()
);

export const clearPages = createAction(
  '[Page/API] Clear Pages'
);
