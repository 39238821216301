import { createAction, props } from '@ngrx/store';
import { ToastOptions } from '@ionic/core/dist/types/components/toast/toast-interface';

import { SupportedLanguage } from '@services/translation.service';

export const initAppState = createAction('[INIT] Load AppState');
export const initAppStateFinish = createAction('[INIT] Load AppState Success', props<{ data: any; }>());
export const initFinish = createAction('[INIT] Initialization Successful');

export const loadUsersettings = createAction('[APPSTATE] Load Usersettings', props<{
  darkMode: boolean;
  language: SupportedLanguage;
}>());

export const showNotification = createAction('[APPSTATE] Show Nofitication', props<{ payload: ToastOptions; }>());

export const routeChanged = createAction('[APPSTATE] Route Changed', props<{ route: string; }>());

export const setLanguage = createAction('[APPSTATE] Set Language', props<{ language: SupportedLanguage; }>());
export const setLanguageLinks = createAction('[APPSTATE] Set Language Links', props<{
  languageLinks: {
    language: SupportedLanguage;
    url: string
  }[];
}>());
export const switchDarkMode = createAction('[APPSTATE] Switch Dark Mode', props<{ darkMode: boolean; }>());

export const logoutReset = createAction('[LOGOUT] Clear AppState Data');

export const setCookiesConfirmed = createAction(
  '[APPSTATE] Save Cookie-Confirmation-Status To Store',
  props<{ confirmed: boolean | null; }>()
);
