import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { supportedLanguages } from '@services/translation.service';
import { isValidArray } from '@shared/helpers/general.helpers';
import { collectRouteData } from '@shared/helpers/ngrx.helpers';
import { merge } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import * as AppStateActions from './app-state.actions';
import { AppStateFacade } from './app-state.facade';
import { ArchiveStateFacade } from './archive/archive-state.facade';
import { PageStateFacade } from './page/page-state.facade';

@Injectable()
export class AppStateEffects {

  constructor(
    private actions$: Actions,
    private appStateFacade: AppStateFacade,
    private pageStateFacade: PageStateFacade,
    private archiveStateFacade: ArchiveStateFacade
  ) { }

  currentLanguage$ = createEffect(() => {
    return merge(
      this.pageStateFacade.currentPageLanguage$,
      this.archiveStateFacade.currentPageLanguage$,
      this.actions$.pipe(
        ofType(routerNavigationAction),
        map(action => collectRouteData(action?.payload?.routerState)),
        map(routeData => routeData?.data?.language ?? 'default'),
      )
    ).pipe(
      filter(language => !!language),
      concatLatestFrom(() => [
        this.appStateFacade.currentLanguage$
      ]),
      filter(([pathLanguage, currentLanguage]) => pathLanguage !== currentLanguage),
      map(([language]) => AppStateActions.setLanguage({ language }))
    );
  });

  languageLinks$ = createEffect(() => {
    return merge(
      this.pageStateFacade.currentPageUrls$,
      this.archiveStateFacade.currentPageUrls$,
      this.actions$.pipe(
        ofType(routerNavigationAction),
        map(action => collectRouteData(action?.payload?.routerState)),
        map(routeData => routeData?.data?.languageLinks),
      )
    ).pipe(
      filter(languageLinksObj => !!languageLinksObj && typeof languageLinksObj === 'object'),
      map(languageLinksObj => {
        const output = [];
        for (const lang in languageLinksObj) {
          if (!languageLinksObj[lang]) { continue; }
          if (!supportedLanguages.includes(lang)) { continue; }
          output.push({
            language: lang,
            url: languageLinksObj[lang]
          });
        }
        return output;
      }),
      map((languageLinks) => AppStateActions.setLanguageLinks({ languageLinks }))
    );
  });
}
