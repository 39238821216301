import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PageEffects } from './page.effects';
import { PageReducer } from './page.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(PageReducer.featureKey, PageReducer.reducer),
    EffectsModule.forFeature([PageEffects])
  ],
})
export class PageStoreModule { }
