import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { startsWith as _startsWith } from 'lodash-es';

import { PageApiService } from '@services/api/page-api.service';
import { collectRouteData } from '@shared/helpers/ngrx.helpers';

import * as PageActions from './page.actions';
import { PageStateFacade } from './page-state.facade';
import { ApiDefaultPage } from './api-default-page.model';

@Injectable()
export class PageEffects {

  routerNavigated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      map(action => collectRouteData(action?.payload?.routerState)),
      filter(routeData => routeData?.data?.loadType === 'default' && !!routeData?.url),
      map((routeData) => {
        let path = routeData.url ?? '';
        if (!_startsWith(path, '/')) { path = '/' + path; }
        return PageActions.loadPageByPath({ path });
      })
    );
  });

  loadPageByPath$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PageActions.loadPageByPath),
      filter(action => !!action.path),
      concatLatestFrom((action) => [
        this.pageStateFacade.pageByPath$(action.path)
      ]),
      switchMap(([action, existingPage]) =>
        this.pageApiService.loadContents<ApiDefaultPage>(
          action.path,
          !action.forceReload && !!existingPage?.hash ? existingPage.hash : undefined
        ).pipe(
          map(response => !!response?.id ? PageActions.loadPageSuccess({ page: response }) : PageActions.loadPageNotModified()),
          catchError(error => of(PageActions.loadPageFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private pageApiService: PageApiService,
    private pageStateFacade: PageStateFacade
  ) { }

}
