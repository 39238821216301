import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { AppState } from "./app-state.reducer";
import * as AppStateSelectors from './app-state.selectors';
import * as AppStateActions from './app-state.actions';
import * as RouterSelectors from './router.selectors';

@Injectable({ providedIn: 'root' })
export class AppStateFacade {

  public readonly initialized$ = this.store.select(AppStateSelectors.selectInitialized);
  public readonly currentPath$ = this.store.select(RouterSelectors.selectCurrentPath);
  public readonly currentLanguage$ = this.store.select(AppStateSelectors.selectCurrentLanguage);
  public readonly languageLinks$ = this.store.select(AppStateSelectors.selectLanguageLinks);
  public readonly darkMode$ = this.store.select(AppStateSelectors.selectDarkMode);

  constructor(
    private store: Store<AppState>
  ) { }

  switchDarkMode(darkMode: boolean) {
    this.store.dispatch(AppStateActions.switchDarkMode({ darkMode }))
  }
}
