import { MetaReducer, createReducer, on } from '@ngrx/store';

import { environment } from '@env/environment';
import { SupportedLanguage } from '@services/translation.service';

import * as AppStateActions from './app-state.actions';

const featureKey = 'appState';

export interface AppState {
  initialized: boolean;
  currentRoute: string;
  darkMode: boolean;
  currentLanguage: SupportedLanguage;
  languageLinks: {
    language: SupportedLanguage;
    url: string
  }[];
}

const initialState: AppState = {
  initialized: false,
  currentRoute: '',
  darkMode: true,
  currentLanguage: 'default',
  languageLinks: []
};

const reducer = createReducer(
  initialState,

  on(AppStateActions.initAppStateFinish, (state, action) => ({ ...state, ...action.data })),
  on(AppStateActions.initFinish, state => ({ ...state, initialized: true })),
  on(AppStateActions.routeChanged, (state, action) => ({ ...state, currentRoute: action.route })),
  on(AppStateActions.logoutReset, state => ({ ...state, sid: null, cookiesConfirmed: null })),
  on(AppStateActions.setLanguage, (state, action) => ({ ...state, currentLanguage: action.language })),
  on(AppStateActions.setLanguageLinks, (state, action) => ({ ...state, languageLinks: action.languageLinks })),
  on(AppStateActions.switchDarkMode, (state, action) => ({ ...state, darkMode: action.darkMode })),
);

const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const AppStateReducer = {
  featureKey, initialState, reducer, metaReducers
};