import { createFeatureSelector, createSelector } from '@ngrx/store';
import { trim as _trim } from 'lodash-es';

import * as AppStateSelectors from '@store/app-state.selectors';
import { selectCurrentPath } from '@store/router.selectors';

import { ArchiveState, ArchiveReducer } from './archive.reducer';

export const selectPageState = createFeatureSelector<ArchiveState>(
  ArchiveReducer.featureKey
);

export const selectLoading = createSelector(
  selectPageState,
  (state: ArchiveState) => state.loading
);

export const selectPageIds = createSelector(
  selectPageState,
  ArchiveReducer.selectIds
);
export const selectPageEntities = createSelector(
  selectPageState,
  ArchiveReducer.selectEntities
);
export const selectAllPages = createSelector(
  selectPageState,
  ArchiveReducer.selectAll
);
export const selectPagesTotal = createSelector(
  selectPageState,
  ArchiveReducer.selectTotal
);

export const selectCurrentPage = createSelector(
  selectAllPages,
  selectCurrentPath,
  (pages, route) => {
    return pages.find(page => _trim(page.url, '/') === _trim(route, '/'));
  }
);

export const selectCurrentPageLanguage = createSelector(
  selectCurrentPage,
  page => page?.language
);

export const selectCurrentPageUrls = createSelector(
  selectCurrentPage,
  page => page?.urls ?? {}
);

export const selectCurrentPageSeo = createSelector(
  selectCurrentPage,
  page => page?.seo
);

export const selectCurrentPageListItems = createSelector(
  selectCurrentPage,
  (page) => page?.items ?? []
);

export const selectPageByPath = (path: string) => createSelector(
  selectPageEntities,
  (pageEntities) => path && pageEntities[path]
);