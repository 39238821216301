import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';

import { PageCardComponent } from './components/page-card/page-card.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';

import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { StripNewLinePipe } from './pipes/strip-newline.pipe';
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { LazyBgDirective } from './directives/lazy-bg.directive';
import { LazySourceDirective } from './directives/lazy-source.directive';
import { LazyPictureComponent } from './components/lazy-picture/lazy-picture.component';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';

@NgModule({
  declarations: [
    PageCardComponent,
    ImageModalComponent,
    LanguageSelectComponent,
    StripHtmlPipe,
    StripNewLinePipe,
    NewlineToBrPipe,
    PadPipe,
    NoSanitizePipe,
    FileUrlPipe,
    LazyImgDirective,
    LazyBgDirective,
    LazySourceDirective,
    IntersectionObserverDirective,
    LazyPictureComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    NgProgressModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SwiperModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    NgProgressModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PageCardComponent,
    ImageModalComponent,
    LanguageSelectComponent,
    StripHtmlPipe,
    StripNewLinePipe,
    NewlineToBrPipe,
    PadPipe,
    NoSanitizePipe,
    FileUrlPipe,
    LazyImgDirective,
    LazyBgDirective,
    LazySourceDirective,
    IntersectionObserverDirective,
    LazyPictureComponent,
    NotFoundComponent,
    SwiperModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe
  ]
})
export class SharedModule { }