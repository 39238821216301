import { createAction, props } from '@ngrx/store';

import { ApiArchivePage } from './api-archive-page.model';

export const loadPageByPath = createAction(
  '[Archive] Load Page By Path',
  props<{ path: string, forceReload?: boolean }>()
);

export const loadPageSuccess = createAction(
  '[Archive] Load Page Success',
  props<{ page: ApiArchivePage }>()
);

export const loadPageNotModified = createAction(
  '[Archive] Load Page Not Modified'
);

export const loadPageFailure = createAction(
  '[Archive] Load Page Failure',
  props<{ error: any }>()
);
