import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import * as ArchiveSelectors from './archive.selectors';

@Injectable({ providedIn: 'root' })
export class ArchiveStateFacade {

  public readonly loading$ = this.store.select(ArchiveSelectors.selectLoading);
  public readonly pageIds$ = this.store.select(ArchiveSelectors.selectPageIds);
  public readonly pageEntities$ = this.store.select(ArchiveSelectors.selectPageEntities);
  public readonly allPages$ = this.store.select(ArchiveSelectors.selectAllPages);
  public readonly pagesTotal$ = this.store.select(ArchiveSelectors.selectPagesTotal);
  public readonly listItems$ = this.store.select(ArchiveSelectors.selectCurrentPageListItems);
  public readonly currentPage$ = this.store.select(ArchiveSelectors.selectCurrentPage);
  public readonly currentPageLanguage$ = this.store.select(ArchiveSelectors.selectCurrentPageLanguage);
  public readonly currentPageUrls$ = this.store.select(ArchiveSelectors.selectCurrentPageUrls);
  public readonly currentPageSeo$ = this.store.select(ArchiveSelectors.selectCurrentPageSeo);

  constructor(
    private store: Store
  ) { }

  pageByPath$(path: string) {
    return this.store.select(ArchiveSelectors.selectPageByPath(path));
  }
}
