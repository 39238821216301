import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiDefaultPage } from '@store/page/api-default-page.model';

import { ApiService } from './api.service';
import { HttpParamEncoder } from './http-param-encoder';

@Injectable({
  providedIn: 'root',
})
export class PageApiService extends ApiService {
  public loadContents<T>(path: string, hash?: string): Observable<T> {
    let params = new HttpParams({ encoder: new HttpParamEncoder() });

    if (hash) {
      params = params.set('hash', hash);
    }

    return this.getPage(path, params);
  }
}
