import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {

  private unsubscribe$ = new Subject<void>();

  private _storage$: BehaviorSubject<Storage | null> = new BehaviorSubject(null);
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private storage$: Observable<Storage | null> = combineLatest([
    this._storage$.asObservable(),
    this._initialized$.asObservable()
  ]).pipe(
    filter(([, initialized]) => !!initialized),
    map(([storage]) => storage)
  )

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage$.next(storage);
    this._initialized$.next(true);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /**
   * Saves a value to the local-storage. Values can be strings, numbers, booleans but also objects or
   * array (everything which is serializable via JSON.stringify).
   *
   * @param key
   * @param value
   */
  public set(key: string, value: any): void {
    this.storage$.pipe(
      take(1),
      switchMap(async storage => await storage?.set(key, JSON.stringify(value))),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  /**
   * Returns a value from storage.
   *
   * @param key
   */
  public get(key: string): Observable<any> {
    return this.storage$.pipe(
      take(1),
      switchMap(async storage => await storage?.get(key)),
      map(data => data ? JSON.parse(data) : data),
      takeUntil(this.unsubscribe$)
    );
  }

  /**
  * Removes a value from storage
  *
  * @param key
  */
  public remove(key: string): void {
    this.storage$.pipe(
      take(1),
      switchMap(async storage => await storage?.remove(key)),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  /**
  * Returns all keys, that are currently in use
  */
  public keys(): Observable<any> {
    return this.storage$.pipe(
      take(1),
      switchMap(async storage => await storage?.keys()),
      map(data => data ? data.keys : data),
      takeUntil(this.unsubscribe$)
    );
  }

  /**
   * Clears everything from storage (not used)
   */
  public clear() {
    this.storage$.pipe(
      take(1),
      switchMap(async storage => await storage?.clear()),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }
}
