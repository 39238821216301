import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/blur-up/ls.blur-up';

import { SeoService } from '@services/seo.service';
import { AppStateFacade } from '@store/app-state.facade';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  public readonly darkMode$ = this.appStateFacade.darkMode$;

  constructor(
    private platform: Platform,
    private seoService: SeoService,
    private appStateFacade: AppStateFacade
  ) {
    this.initializeApp();
  }

  initializeApp() {
    Object.assign(lazySizes.cfg, {
      blurupMode: 'auto'
    });
  }

  ngOnInit() {
    this.darkMode$.pipe(
      takeUntil(this.unsubscribe$),
      tap(darkMode => {
        // Update Favicon:
        if (darkMode) {
          this.seoService.setHeadTags([
            {
              tag: 'link',
              selector: 'link[rel="shortcut icon"]',
              attributeValues: {
                rel: 'shortcut icon',
                href: 'assets/favicon/fav_dark/favicon.ico'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][type="image/x-icon"][sizes="16x16 32x32"]',
              attributeValues: {
                rel: 'icon',
                type: 'image/x-icon',
                sizes: '16x16 32x32',
                href: 'assets/favicon/fav_dark/favicon.ico'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="152x152"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '152x152',
                href: 'assets/favicon/fav_dark/favicon-152-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="144x144"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '144x144',
                href: 'assets/favicon/fav_dark/favicon-144-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="120x120"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '120x120',
                href: 'assets/favicon/fav_dark/favicon-120-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="114x114"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '114x114',
                href: 'assets/favicon/fav_dark/favicon-114-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="180x180"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: 'assets/favicon/fav_dark/favicon-180-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="72x72"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '72x72',
                href: 'assets/favicon/fav_dark/favicon-72-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="57x57"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '57x57',
                href: 'assets/favicon/fav_dark/favicon-57-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][sizes="32x32"]',
              attributeValues: {
                rel: 'icon',
                sizes: '32x32',
                href: 'assets/favicon/fav_dark/favicon-32.png'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="msapplication-TileColor"]',
              attributeValues: { content: '#000000' }
            },
            {
              tag: 'meta',
              selector: 'meta[name="msapplication-TileImage"]',
              attributeValues: {
                name: 'msapplication-TileImage',
                content: 'assets/favicon/fav_dark/favicon-144.png'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="theme-color"]',
              attributeValues: {
                name: 'theme-color',
                content: '#000000'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][sizes="192x192"]',
              attributeValues: {
                rel: 'icon',
                sizes: '192x192',
                href: 'assets/favicon/fav_dark/favicon-192.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="manifest"]',
              attributeValues: {
                rel: 'manifest',
                href: 'assets/favicon/fav_dark/manifest.webmanifest'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="apple-mobile-web-app-status-bar-style"]',
              attributeValues: {
                name: 'apple-mobile-web-app-status-bar-style',
                content: 'black'
              }
            }
          ]);
        } else {
          this.seoService.setHeadTags([
            {
              tag: 'link',
              selector: 'link[rel="shortcut icon"]',
              attributeValues: {
                rel: 'shortcut icon',
                href: 'assets/favicon/fav_light/favicon.ico'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][type="image/x-icon"][sizes="16x16 32x32"]',
              attributeValues: {
                rel: 'icon',
                type: 'image/x-icon',
                sizes: '16x16 32x32',
                href: 'assets/favicon/fav_light/favicon.ico'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="152x152"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '152x152',
                href: 'assets/favicon/fav_light/favicon-152-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="144x144"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '144x144',
                href: 'assets/favicon/fav_light/favicon-144-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="120x120"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '120x120',
                href: 'assets/favicon/fav_light/favicon-120-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="114x114"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '114x114',
                href: 'assets/favicon/fav_light/favicon-114-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="180x180"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: 'assets/favicon/fav_light/favicon-180-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="72x72"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '72x72',
                href: 'assets/favicon/fav_light/favicon-72-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="apple-touch-icon"][sizes="57x57"]',
              attributeValues: {
                rel: 'apple-touch-icon',
                sizes: '57x57',
                href: 'assets/favicon/fav_light/favicon-57-precomposed.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][sizes="32x32"]',
              attributeValues: {
                rel: 'icon',
                sizes: '32x32',
                href: 'assets/favicon/fav_light/favicon-32.png'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="msapplication-TileColor"]',
              attributeValues: {
                name: 'msapplication-TileColor',
                content: '#FFFFFF'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="msapplication-TileImage"]',
              attributeValues: {
                name: 'msapplication-TileImage',
                content: 'assets/favicon/fav_light/favicon-144.png'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="theme-color"]',
              attributeValues: {
                name: 'theme-color',
                content: '#FFFFFF'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="icon"][sizes="192x192"]',
              attributeValues: {
                rel: 'icon',
                sizes: '192x192',
                href: 'assets/favicon/fav_light/favicon-192.png'
              }
            },
            {
              tag: 'link',
              selector: 'link[rel="manifest"]',
              attributeValues: {
                rel: 'manifest',
                href: 'assets/favicon/fav_light/manifest.webmanifest'
              }
            },
            {
              tag: 'meta',
              selector: 'meta[name="apple-mobile-web-app-status-bar-style"]',
              attributeValues: {
                name: 'apple-mobile-web-app-status-bar-style',
                content: 'white'
              }
            }
          ]);
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public async getUserSettings() {
    const output: any = {};



    return output;
  }
}
