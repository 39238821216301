import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { startsWith as _startsWith } from 'lodash-es';
import { routerNavigationAction } from '@ngrx/router-store';

import { PageApiService } from '@services/api/page-api.service';
import { collectRouteData } from '@shared/helpers/ngrx.helpers';

import * as ArchiveActions from './archive.actions';
import { ArchiveStateFacade } from './archive-state.facade';
import { ApiArchivePage } from './api-archive-page.model';

@Injectable()
export class ArchiveEffects {

  routerNavigated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      map(action => collectRouteData(action?.payload?.routerState)),
      filter(routeData => routeData?.data?.loadType === 'archive' && !!routeData?.url),
      map((routeData) => {
        let path = routeData.url ?? '';
        if (!_startsWith(path, '/')) { path = '/' + path; }
        return ArchiveActions.loadPageByPath({ path });
      })
    );
  });

  loadPageByPath$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArchiveActions.loadPageByPath),
      filter(action => !!action.path),
      concatLatestFrom((action) => [
        this.archiveStateFacade.pageByPath$(action.path)
      ]),
      switchMap(([action, existingPage]) =>
        this.pageApiService.loadContents<ApiArchivePage>(
          action.path,
          !action.forceReload && !!existingPage?.hash ? existingPage.hash : undefined
        ).pipe(
          map(response => !!response?.id ? ArchiveActions.loadPageSuccess({ page: response }) : ArchiveActions.loadPageNotModified()),
          catchError(error => of(ArchiveActions.loadPageFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private archiveStateFacade: ArchiveStateFacade,
    private pageApiService: PageApiService
  ) { }

}
