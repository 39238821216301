import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core/dist/types/components/toast/toast-interface';
import { isElement as _isElement } from 'lodash-es';

import { TranslationService } from '@services/translation.service';
import { AppStateFacade } from '@store/app-state.facade';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UtilService implements OnDestroy {

  private unsubscribe$ = new Subject<void>();


  constructor(
    private toastController: ToastController,
    private translationService: TranslationService,
    private storageService: StorageService,
    private appStateFacade: AppStateFacade
  ) {
    this.appStateFacade.darkMode$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(darkMode => {
      document.body.classList.toggle('dark', darkMode);
      document.body.classList.toggle('white', !darkMode);
    });

    this.restoreInitialLanguage();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  restoreInitialLanguage() {
    this.storageService.get('darkMode').pipe(
      tap(darkModeFromStore => {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const darkmodeBrowser = prefersDark.matches;

        let darkMode = false;
        if (darkModeFromStore !== undefined && typeof darkModeFromStore === 'boolean') {
          darkMode = darkModeFromStore;
        } else if (typeof darkmodeBrowser === 'boolean') {
          darkMode = darkmodeBrowser;
        }

        this.setDarkMode(darkMode, false);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  public setDarkMode(darkMode: boolean, shouldSave = true) {
    if (typeof darkMode !== 'boolean') { return; }
    this.appStateFacade.switchDarkMode(darkMode);
    if (shouldSave) { this.storageService.set('darkMode', darkMode); }
  }

  /**
   * Shows a notification-message as a toast. You can use translation-Keys for header- and message-values.
   * https://ionicframework.com/docs/api/toast
   */
  public showNotification(payload: ToastOptions, translationParams?: { [key: string]: string }): Promise<any> {
    return new Promise(async resolve => {
      if (typeof payload !== 'object' || !payload.message) {
        resolve(null);
        return;
      }

      const header = payload.header ? '' + payload.header : '';
      const message = payload.message ? '' + payload.message : '';

      combineLatest([
        this.translationService.getTranslation$(header, translationParams),
        this.translationService.getTranslation$(message, translationParams)
      ]).pipe(
        takeUntil(this.unsubscribe$),
        first(),
        map(([translatedHeader, translatedMessage]) =>
          [
            typeof translatedHeader === 'string' && translatedHeader.length > 0 ?
              translatedHeader :
              header,
            typeof translatedMessage === 'string' && translatedMessage.length > 0 ?
              translatedMessage :
              message
          ]
        ),
      ).subscribe(async ([translatedHeader, translatedMessage]) => {
        const toast = await this.toastController.create({
          duration: 0,
          buttons: [
            {
              side: 'end',
              icon: 'close-outline'
            }
          ],
          ...payload,
          header: translatedHeader,
          message: translatedMessage
        });
        toast.present();

        toast.onDidDismiss().then(
          toastResponse => resolve(toastResponse)
        );
      });
    });
  }
}
