import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
    data: {
      loadType: 'default'
    }
  },
  {
    path: 'de',
    data: {
      language: 'de'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
        data: {
          loadType: 'default'
        }
      },
      {
        path: 'projects',
        loadChildren: () => import('@pages/archive/archive.module').then(m => m.ArchivePageModule),
        data: {
          loadType: 'archive'
        }
      },
      {
        path: '**',
        loadChildren: () => import('@pages/default/default.module').then(m => m.DefaultPageModule),
        data: {
          loadType: 'default'
        }
      }
    ]
  },
  {
    path: 'projects',
    loadChildren: () => import('@pages/archive/archive.module').then(m => m.ArchivePageModule),
    data: {
      loadType: 'archive'
    }
  },
  {
    path: '**',
    loadChildren: () => import('@pages/default/default.module').then(m => m.DefaultPageModule),
    data: {
      loadType: 'default'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  // constructor(menuService: MenuService) {
  //   const href = window.location.href;
  //   if (href.indexOf('#') > -1) {
  //     menuService.defaultPage = href.substring(href.indexOf('#') + 2);
  //   }
  // }
}
