import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { SocialFabComponent } from './social-fab.component';

@NgModule({
  declarations: [
    SocialFabComponent
  ],
  exports: [
    SocialFabComponent
  ],
  imports: [
    SharedModule
  ]
})
export class SocialFabModule { }
